import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "bruces-personal-docz"
    }}>{`Bruce's Personal Docz`}</h1>
    <p><a parentName="p" {...{
        "href": "https://docz.brucebentley.dev/"
      }}>{`Live Example`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      